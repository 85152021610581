import {useEffect, useState} from "react";
import {format} from "date-fns";
import {IRevenueResponseItemDto} from "../../dtos/rooms/IRevenueResponseItemDto";
import {AppDependencies} from "../../services/appDependencies";
import {RevenueFilter} from "./RevenueFilter";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hooks/reduxHooks";
import {getSelectedHotel} from "../../redux/slices/mainSlice";

export const RevenueCard = () => {
    const {t} = useTranslation();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [items, setItems] = useState<IRevenueResponseItemDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const selectedHotel = useAppSelector(getSelectedHotel);

    const fetchData = async () => {
        const newItems = await AppDependencies.RevenueService.GetRevenue(startDate, endDate, selectedHotel?.id);
        setItems(newItems ?? []);
    }

    useEffect(() => {
        fetchData();
    }, [startDate, endDate, selectedHotel]);

    const handleOpenFilter = () => {
        setIsFilterOpen(true)
    }
    
    const handleOnHide = (success: boolean, newStartDate?: Date, newEndDate?: Date) => {
        setIsFilterOpen(false);
        if (success) {
            setStartDate(newStartDate!);
            setEndDate(newEndDate!);
        }
    }

    return (
        <>
            <div className="card shadow-lg mb-3">
                <div className="card-header" style={{minHeight: "6vh"}}>
                    {error && <div className="alert alert-danger" role="alert"/>}
                    <div className="d-flex">
                        <div>
                            <div className="fw-bold">
                                {t("RevenueForecast")}
                            </div>
                            <div>
                                <small>{format(startDate, "dd/MM/yyy")} - {format(endDate, "dd/MM/yyy")}</small>
                            </div>
                        </div>
                        <div className="flex-grow-1">
                        </div>
                        <div>
                            <button type="button" className="btn btn-sm btn-outline-secondary"
                                    onClick={handleOpenFilter}>
                                <i className="fa-solid fa-filter"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <table className="table table-sm table-hover">
                        <thead>
                        <tr>
                            <th>{t("Room")}</th>
                            <th>{t("Revenue")}</th>
                            <th>{t("Forecast")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td>{item.roomName}</td>
                                <td>{item.actualAmount}</td>
                                <td>{item.estimateAmount}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {isFilterOpen && <RevenueFilter startDate={startDate} endDate={endDate} show={isFilterOpen} onHide={handleOnHide}/>}
        </>
    )
}