import {EventType} from "../../enums/EventType";
import React, {useEffect} from "react";
import {IDocumentAmountResponseDto} from "../../dtos/rooms/IDocumentAmountResponseDto";
import {AppDependencies} from "../../services/appDependencies";
import {format} from "date-fns";

export const CalendarTooltip = ({documentId, eventType, documentNo}: {
    documentId: string,
    eventType: EventType,
    documentNo: string
}) => {
    const [currentAppointmentAmounts, setCurrentAppointmentAmounts] = React.useState<IDocumentAmountResponseDto[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | undefined>(undefined);


    const getColorByEventType = (eventType: EventType) => {
        switch (eventType) {
            case EventType.Reservation:
                return 'blue';
            case EventType.Accommodation:
                return 'green';
            case EventType.InHouse:
                return '#AA02BD';
            default:
                return 'blue';
        }
    }


    const loadDocumentAmounts = async (documentId: string) => {
        try {
            setIsLoading(true);
            const axios = AppDependencies.Axios;
            const response = await axios.get(`/Sales/${documentId}`);
            const items = response.data.items;
            
            const newDocumentAmounts: IDocumentAmountResponseDto[] = []
            items.map((item: any) => {
                newDocumentAmounts.push({
                    period: item.period,
                    estimateAmount: 0,
                    actualAmount: item.roomRevenue - item.commissionAmount,
                });
            });
            
            const responseForecast = await axios.get(`/SalesForecast/${documentId}`);
            const itemsForecast = responseForecast.data.items;
            itemsForecast.map((item: any) => {
                newDocumentAmounts.push({
                    period: item.period,
                    estimateAmount: item.salesAmount,
                    actualAmount: 0,
                });
            });
            
            const sortedAmounts = newDocumentAmounts.sort((a, b) => {
                if (a.period > b.period) return 1;
                if (a.period < b.period) return -1;
                return 0;
            });
            // const service = AppDependencies.CalendarService;
            // const documentAmounts = await service.GetDocumentAmounts(documentId);
            setCurrentAppointmentAmounts(sortedAmounts);
        } catch (e: any) {
            setError(e.message);
            setCurrentAppointmentAmounts([]);
        } finally {
            setIsLoading(false);
        }
        // console.log("documentAmounts", documentAmounts);
        // return documentAmounts;
    }


    useEffect(() => {
        loadDocumentAmounts(documentId);
    }, [documentId, eventType]);

    if (isLoading) return <>Loading</>
    if (currentAppointmentAmounts.length === 0) return (
        <>
            <b>{documentNo}</b>
            <div>No data</div>
        </>
    )
    if (error) return <>Failed to load data</>

    return (
        <>
            123
            <b>{documentNo}</b>
            <div className={"days-list-popup"}>
                {currentAppointmentAmounts.map((roomDayEvent: IDocumentAmountResponseDto, index: number) => {
                    return <div key={index} style={{color: getColorByEventType(eventType)}}
                                className={"fw-bold text-start"}>{format(roomDayEvent.period, "dd-MM-yyyy")} - {`${(roomDayEvent.estimateAmount + roomDayEvent.actualAmount).toFixed(2)} GEL`}</div>
                })
                }
            </div>
        </>
    )
}