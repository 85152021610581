import {IRoomEventDto} from "../../dtos/rooms/IRoomEventDto";
import {format} from "date-fns";
import {EventType} from "../../enums/EventType";
import {useTranslation} from "react-i18next";

interface IProps {
    roomEvents: IRoomEventDto[];
}

export const RoomEventsCard = ({roomEvents}: IProps) => {
    const {t} = useTranslation();
    const getEventName = (eventType: EventType) => {
        switch (eventType) {
            case EventType.Accommodation:
                return t("Accommodation");
            case EventType.InHouse:
                return t("In House");
            case EventType.Reservation:
                return t("Reservation");
            default:
                return t("Unknown");
        }
    }

    return (
        <>
            <div className="card shadow-lg">
                <div className="card-header fw-bold" style={{minHeight: "6vh"}}>
                    {t("EventList")}
                </div>
                <div className="card-body overflow-auto" style={{maxHeight: 350}}>
                    <ul>
                        {roomEvents.map((roomEvent, index) => {
                                return (
                                    <li key={index}>{`${roomEvent.roomName}: ${getEventName(roomEvent.eventType)} from ${format(roomEvent.checkInDate, "dd/MM/yyyy")} to ${format(roomEvent.checkOutDate, "dd/MM/yyyy")}, ${t("Amount")}: ${(roomEvent.estimateAmount + roomEvent.actualAmount).toFixed(2)}`}</li>
                                )
                            }
                        )}
                    </ul>
                </div>
            </div>
        </>
    )
}