import {useAppSelector} from "../../hooks/reduxHooks";
import {getSelectedApartments, getSelectedHotel} from "../../redux/slices/mainSlice";
import React, {useCallback, useEffect, useRef} from "react";
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";
import "./CalendarPage.css";
import {endOfDay, startOfDay, startOfMonth} from "date-fns";
import {RevenueCard} from "./RevenueCard";
import {RoomEventsCard} from "./RoomEventsCard";
import {Scheduler} from "devextreme-react";
import {AppDependencies} from "../../services/appDependencies";
import {Resource} from "devextreme-react/scheduler";
import {EventType} from "../../enums/EventType";
import {IRoomEventDto} from "../../dtos/rooms/IRoomEventDto";
import {useTranslation} from "react-i18next";
import {IRoomDayEventDto} from "../../dtos/rooms/IRoomDayEventDto";
import {CreateReservationModal} from "./CreateReservationModal";
import {AppointmentFormOpeningEvent} from "devextreme/ui/scheduler";
import {IRoomEventAppointment} from "../../dtos/rooms/IRoomEventAppointment";
import {CalendarTooltip} from "./CalendarTooltip";


export const eventTypeResources = [
    {
        text: 'Reservation',
        id: EventType.Reservation,
        color: 'blue',
    }, {
        text: 'Accommodation',
        id: EventType.Accommodation,
        color: 'green',
    }, {
        text: 'House Use',
        id: EventType.InHouse,
        color: '#AA02BD',
    },
];

const CalendarPage = () => {
    const {t} = useTranslation();
    const selectedApartments = useAppSelector(getSelectedApartments);
    const selectedHotel = useAppSelector(getSelectedHotel);

    const [appointments, setAppointments] = React.useState<IRoomEventAppointment[]>([]);
    const [roomEvents, setRoomEvents] = React.useState<IRoomEventDto[]>([]);
    const [roomDayEvents, setRoomDayEvents] = React.useState<IRoomDayEventDto[]>([]);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | undefined>(undefined);

    const [calendarMonth, setCalendarMonth] = React.useState<Date>(startOfMonth(new Date()));
    const [showCreateReservationModal, setShowCreateReservationModal] = React.useState<boolean>(false);
    
    let calendarRef = useRef<Scheduler | null>(null);

    const fetchData = useCallback(async () => {
        if (calendarRef.current === null) return;
        
        try {
            const startCalendarDate =  startOfDay(calendarRef.current?.instance.getStartViewDate()); //startOfMonth(calendarMonth);
            const endCalendarDate = endOfDay(calendarRef.current?.instance.getEndViewDate());//endOfMonth(startCalendarDate);

            setIsLoading(true);
            setError(undefined);

            const roomEvents = await AppDependencies.CalendarService.GetEvents();
            setRoomEvents(roomEvents);

            const appointments = await AppDependencies.CalendarService.GetCalendarAppointments(startCalendarDate, endCalendarDate);
            setAppointments(appointments);
        } catch (e: any) {
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    }, [selectedHotel, calendarMonth]);

    useEffect(() => {
        // console.log("Use effect1");
        fetchData();
    }, [selectedHotel, calendarMonth]);

    useEffect(() => {
        // console.log("Use effect2");
        fetchData();
    }, [selectedHotel]);

    const renderAppointment = (model: any) => {
        return (
            <>
                <div>{model.appointmentData.text}</div>
            </>
        );
    }


    const renderAppointmentTooltip = (model: any) => {

        return <CalendarTooltip documentId={model.appointmentData.documentId}
                                documentNo={model.appointmentData.description}
                                eventType={model.appointmentData.eventType}/>
    }

    const handleShowCreteReservationModal = () => {
        setShowCreateReservationModal(true);
    }

    const handleCurrentDateChange = (newCalendarDate: Date) => {
        console.log(newCalendarDate);
        setCalendarMonth(newCalendarDate);
    }

    const handleAppointmentFormOpening = useCallback((e: AppointmentFormOpeningEvent) => {
        e.cancel = true;
    }, []);

    return (
        <>
            <Breadcrumb>
                <BreadcrumbItem>{t("Home")}</BreadcrumbItem>
                <BreadcrumbItem active>{t("Calendar")}</BreadcrumbItem>
            </Breadcrumb>
            <div className="container-fluid">
                {error && <div className="alert alert-danger">{error}</div>}
                <div className={"row mb-2"}>
                    <div className={"col-sm-12 col-md-4"}>
                        <button className={"btn btn-primary"}
                                onClick={handleShowCreteReservationModal}>{t("CreateReservation")}</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-8 mb-4">
                        <Scheduler
                            ref={calendarRef}
                            timeZone="Asia/Tbilisi"
                            editing={false}
                            dataSource={appointments}
                            views={["month"]}
                            defaultCurrentView="month"
                            defaultCurrentDate={calendarMonth}
                            onCurrentDateChange={handleCurrentDateChange}
                            startDayHour={0}
                            endDayHour={24}
                            height={600}
                            appointmentRender={renderAppointment}
                            appointmentTooltipRender={renderAppointmentTooltip}
                            onAppointmentFormOpening={handleAppointmentFormOpening}
                        >
                            <Resource
                                dataSource={eventTypeResources}
                                fieldExpr="eventType"
                                label="Event Type"
                                useColorAsDefault={true}
                            />
                        </Scheduler>
                        <div>
                            <div className="d-flex align-items-center">
                                <div style={{width: 15, height: 15, backgroundColor: "blue"}}></div>
                                <span className="ms-2">{t("Reservation")}</span>
                            </div>
                            <div className="d-flex align-items-center">
                                <div style={{width: 15, height: 15, backgroundColor: "green"}}></div>
                                <span className="ms-2">{t("Accommodation")}</span>
                            </div>
                            <div className="d-flex align-items-center">
                                <div style={{width: 15, height: 15, backgroundColor: "#AA02BD"}}></div>
                                <span className="ms-2">{t("HouseUse")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <RevenueCard/>
                        <RoomEventsCard roomEvents={roomEvents}/>
                    </div>
                </div>
            </div>
            {showCreateReservationModal && <CreateReservationModal show={showCreateReservationModal}
                                                                   onClose={() => setShowCreateReservationModal(false)}/>}
        </>
    )
}

export default CalendarPage;